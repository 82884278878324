import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import Image from '../core/Image';

export const CarouselWrapper = styled.div`
  width: 100%;
  min-width: 800px;
  box-sizing: border-box;
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    margin-bottom: 2.75rem;
  }
  &.componentSection {
    .carouselNavigationThumbnailWrapper {
      text-align: center;
      .gatsby-image-wrapper,
      img {
        width: 130px;
        height: 130px;
        margin: 0 auto;
        display: block;
        margin-bottom: 1.1875rem;
        object-fit: contain !important;
      }
      a {
        font-size: 0.8125rem;
        line-height: 1.25rem;
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

export const CarouselFeatureImage = styled.img`
  width: 800px;
  height: 800px;
  object-fit: contain;
  object-position: center center;
  ${themeGet('borders.default')};
  box-sizing: border-box;
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    display: none;
  }
`;
export const CarouselThumbnailImage = styled(Image)`
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.thumbnailWidth}px;
  object-fit: contain;
  object-position: center center;
  box-sizing: border-box;
  cursor: pointer;
  pointer-events: all;
  &.active {
    border: 2px solid ${themeGet('colors.spinDark')};
  }
  a {
    display: inline-block;
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    cursor: auto;
    width: 100%;
    height: 100%;
    max-width: unset;
  }
`;
export const CarouselNavigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 4.25rem;
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    margin-top: 1rem;
  }
`;
export const CarouselNavigationButton = styled.div`
  opacity: 0.5;
  transition: 0.5s all ease-out;
  pointer-events: none;
  cursor: pointer;
  &.buttonPrev {
    img {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
  img {
    width: 12px;
    height: 20px;
    display: inline-block;
    &.enabled {
      display: none;
    }
  }
  &.enabled {
    pointer-events: all;
    &:hover {
      opacity: 1;
    }
    img {
      display: none;
      &.enabled {
        display: inline-block;
      }
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    display: none;
  }
`;
export const CarouselNavigationThumbnailWrapper = styled.div`
  width: calc(
    ${(props) => props.thumbnailWidth}px * ${(props) => props.thumbnailsPerPage} +
      ${(props) => props.thumbnailPadding}px * 2
  );
  /* width: Calc(100% - 28px - 96px); */
  overflow: hidden;
  position: relative;
  ul {
    position: relative;
    list-style: none;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    transition: 0.5s all ease-out;
    display: inline-block;
    /* display: flex; */
    li {
      display: inline-block;
      margin-right: ${themeGet('document.gapColumn')};
      width: ${(props) => props.thumbnailWidth}px;
      height: auto;
      white-space: normal;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    width: Calc(100vw - 2rem);
    overflow-x: scroll;
    padding-bottom: 1.5rem;
    ul {
      left: 0 !important;
      li {
        width: Calc(100vw - 2rem);
      }
    }
  }
`;
