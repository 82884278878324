import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { themeGet } from '@styled-system/theme-get';
import _ from 'lodash';

import arrowRight from '../../images/arrowRight.svg';
import arrowRightDisabled from '../../images/arrowRight_disabled.svg';
import { getSrc } from 'gatsby-plugin-image';

import {
  CarouselWrapper,
  CarouselFeatureImage,
  CarouselThumbnailImage,
  CarouselNavigation,
  CarouselNavigationThumbnailWrapper,
  CarouselNavigationButton,
} from './styles';

const ProductCarousel = ({
  images,
  components,
  featureEnabled,
  columns,
  sys,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  if (!images && !components) {
    return null;
  }
  const items = images || components[0].listComponents;
  const navigationPadding = 6 * 16;
  const thumbnailsPerPage =
    sys?.contentType.sys.id === 'componentSection' ? 5 : 3;
  const thumbnailPadding = 2 * 20;
  const containerWidth =
    sys?.contentType.sys.id === 'componentSection' ? 1600 : 800;
  const thumbnailWidth = Math.floor(
    (containerWidth -
      (thumbnailsPerPage - 1) * thumbnailPadding -
      navigationPadding) /
      thumbnailsPerPage
  );

  console.log('items[activeIndex]', items[activeIndex])
  return (
    <CarouselWrapper
      className={`carouselWrapper${` ${sys?.contentType.sys.id}` || ''}`}
    >
      {items[activeIndex]?.fileNode?.publicURL && featureEnabled && (
        <CarouselFeatureImage
          // src={getSrc(items[activeIndex]?.fileNode?.publicURL)}
          src={items[activeIndex]?.fileNode?.publicURL}
          className="carouselFeatureImage defaultBorder"
        />
      )}
      <CarouselNavigation className="carouselNavigation">
        <CarouselNavigationButton
          onClick={() => {
            setActiveIndex(activeIndex - 1);
          }}
          className={`buttonPrev ${activeIndex > 0 ? 'enabled' : ''}`}
        >
          <img alt="" src={arrowRight} className="enabled" />
          <img alt="" src={arrowRightDisabled} className="disabled" />
        </CarouselNavigationButton>
        <CarouselNavigationThumbnailWrapper
          thumbnailsPerPage={thumbnailsPerPage}
          thumbnailWidth={thumbnailWidth}
          thumbnailPadding={thumbnailPadding}
          className="carouselNavigationThumbnailWrapper"
        >
          <ul
            style={{
              left:
                activeIndex < items.length - thumbnailsPerPage
                  ? `${0 - (thumbnailWidth + thumbnailPadding) * activeIndex}px`
                  : `${
                      0 -
                      (thumbnailWidth + thumbnailPadding) *
                        (items.length - thumbnailsPerPage + 1)
                    }px`,
            }}
          >
            {items.map((item, index) => {
              console.log('item.publicURL', item.publicURL);
              console.log(
                `_.get(item,'mediaAsset.file.url', null)`,
                _.get(item, 'mediaAsset.file.url', null)
              );

              const productImageSharp =
                item.childImageSharp?.gatsbyImage ||
                _.get(item, 'mediaAsset', null);
              const productImageSrc =
                item.publicURL || _.get(item, 'mediaAsset.file.url', null);
              const contentType = item.contentType
                ? item.contentType
                : item.mediaAsset && item.mediaAsset.file.contentType
                ? item.mediaAsset.file.contentType.split('/')[0]
                : null;
              const link = item.externalLink || item.linkUrl;
              const thumbnailElement =
                item.childImageSharp || item.mediaAsset ? (
                  <>
                    {productImageSrc && (
                      <CarouselThumbnailImage
                        thumbnailWidth={thumbnailWidth}
                        src={productImageSrc}
                        imageSharp={productImageSharp}
                        className={
                          `defaultBorder${index}` === activeIndex
                            ? ' active'
                            : ''
                        }
                      />
                    )}
                    {item.textLink && item.textLink}
                  </>
                ) : null;
              return thumbnailElement ? (
                <li
                  key={`thumbnailElement_${item.salsifyId}_${index}`}
                  onClick={() => {
                    if (window.innerWidth > 815) {
                      setActiveIndex(index);
                    }
                  }}
                >
                  {link && (
                    <a
                      href={link}
                      target={
                        link && link.indexOf('http') === -1 ? '_self' : '_blank'
                      }
                    >
                      {thumbnailElement}
                    </a>
                  )}
                  {!link && thumbnailElement}
                </li>
              ) : null;
            })}
          </ul>
        </CarouselNavigationThumbnailWrapper>
        <CarouselNavigationButton
          onClick={() => {
            if (activeIndex < items.length - 1) {
              setActiveIndex(activeIndex + 1);
            }
          }}
          className={
            activeIndex < items.length - 1 && items.length > thumbnailsPerPage
              ? 'enabled'
              : ''
          }
        >
          <img alt="" src={arrowRight} className="enabled" />
          <img alt="" src={arrowRightDisabled} className="disabled" />
        </CarouselNavigationButton>
      </CarouselNavigation>
    </CarouselWrapper>
  );
};

ProductCarousel.propTypes = {
  images: PropTypes.array,
  links: PropTypes.array,
  featureEnabled: PropTypes.bool,
  columns: PropTypes.number,
  resolverType: PropTypes.string,
};
ProductCarousel.defaultProps = {
  images: null,
  links: null,
  featureEnabled: false,
  columns: null,
  resolverType: null,
};
export default ProductCarousel;
